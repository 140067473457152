import { Col, Image, Input, Row, Typography } from "antd";

const JobMatching = () => {
  return (
    <>
      <Typography className="font-normal">
        Intelligent Job Matching
      </Typography>
      <p>Headhuntable is always learning about you as you work through your career so next time you are out on the market, jsut turn Headhuntable on
        and the next opportunities will be added to your calendar within a few days.
      </p>
    </>
  );
};

export default JobMatching;