// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroller {
  height: 163px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
}

.scroller__wrapper {
  height: 100%;
  width: 100%;
  animation: marquee 20s linear infinite;
}

.scroller__image {
  min-width: 120px;
  width: auto;
}

.scroller-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 150px;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
    transition: all 0.2s;
  }
  to {
    transform: translateX(-100%);
    transition: all 0.2s;
  }
}

@media screen and (max-width: 768px) {
  .scroller {
    height: 100% !important;
    padding: 0 !important;
  }
  .scroller__wrapper {
    animation-duration: 10s;
  }
  .scroller-container {
    gap: 10px !important;
  }
  .scroller__image {
    min-width: 90px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/auto-scroller/autoscroller.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,aAAa;EACb,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,sCAAsC;AACxC;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE;IACE,2BAA2B;IAC3B,oBAAoB;EACtB;EACA;IACE,4BAA4B;IAC5B,oBAAoB;EACtB;AACF;;AAEA;EACE;IACE,uBAAuB;IACvB,qBAAqB;EACvB;EACA;IACE,uBAAuB;EACzB;EACA;IACE,oBAAoB;EACtB;EACA;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".scroller {\n  height: 163px;\n  overflow: hidden;\n  box-sizing: border-box;\n  padding: 10px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.scroller__wrapper {\n  height: 100%;\n  width: 100%;\n  animation: marquee 20s linear infinite;\n}\n\n.scroller__image {\n  min-width: 120px;\n  width: auto;\n}\n\n.scroller-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 150px;\n}\n\n@keyframes marquee {\n  from {\n    transform: translateX(100%);\n    transition: all 0.2s;\n  }\n  to {\n    transform: translateX(-100%);\n    transition: all 0.2s;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .scroller {\n    height: 100% !important;\n    padding: 0 !important;\n  }\n  .scroller__wrapper {\n    animation-duration: 10s;\n  }\n  .scroller-container {\n    gap: 10px !important;\n  }\n  .scroller__image {\n    min-width: 90px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
