import { Col, Image, Input, Row, Typography } from "antd";

const DescriptionOpportunityBrain = () => {
  return (
    <>
      <section className="relative z-10">
        <Typography className="mt-4 font-Space-Grotesk font-normal">
          What Is The Point Of The "Opportunity Brain"?
        </Typography>
        <p>Well in short, the point is to learn about you. It learns what jobs and opportunities you love whether it be contract or full time work; and learns about the projects that you LOVE.</p>
      </section>
    </>
  );
};

export default DescriptionOpportunityBrain;