import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";

const MainLayout = ({ children, isHomePage, headerTitle, subHeaderTitle }) => {
  return (
    <div>
      <Navbar />
      <Header
        isHomePage={isHomePage}
        headerTitle={headerTitle}
        subHeaderTitle={subHeaderTitle}
      />
      {children}
      <Footer />
    </div>
  );
};

export default MainLayout;
