import { Image, Typography } from "antd";

const Item = ({ image, title, description }) => {
  return (
    <section className="flex gap-[24px] mt-[50px] ">
      <div>
        <Image
          src={`/images/howDoesItWork/${image}`}
          preview={false}
          className={`p-[15px] ${
            title !== "full time assistant" ? "bg-[#fff]" : "bg-[#0026F2]"
          } border-[1px] border-[#E2E2E2]`}
        />
      </div>
      <div className="flex flex-col">
        <Typography className="font-Space-Grotesk text-[24px] text-[#272727] font-semibold tracking-[-0.72px] capitalize">
          {title}
        </Typography>
        <Typography className="font-Red-Hat-Display text-[16px] text-[#272727]/50 font-semibold tracking-[-0.72px]">
          {description}
        </Typography>
      </div>
    </section>
  );
};

const NextRole = () => {
  return (
    <section className="px-20 relative z-0 bg-[#f3f4f6]/50 py-[80px]">
      <section className=" flex justify-between">
        <Typography className="flex-1 font-Space-Grotesk font-semibold text-[#272727] text-[38px] leading-[48.49px] capitalize">
          <span className="text-[#0026F2]">Headhuntable</span>
        </Typography>
        <Typography
          className="flex-1 font-Red-Hat-Display font-normal text-[#272727] text-[20px] leading-[
            25.6px]"
        >
          When you join with Headhuntable you will just provide your resume and
          coversheet. After that you will just receive invitations to speak to
          different companies and have a weekly mentoring call with your mentor
          to help you close the companies/close the work.
        </Typography>
      </section>
    </section>
  );
};

export default NextRole;
