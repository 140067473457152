// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature-card-single-item {
  /* width: 298px; */
  height: 303px;
  border: 1px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
}

.feature-card-icon-container {
  min-height: 60px;
  max-height: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 100%;
  width: 100%;
  border: 1px solid #0026f2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-card-icon {
  height: 40px;
  width: 40px;
}

.feature-card-title {
  font-family: Space Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.feature-card-detail {
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.feature-label {
  color: #4c4356;
  font-family: Space Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

@media screen and (max-width: 1200px) {
  .feature-card-title {
    font-size: 18px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/featurePage/features.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;EAC3B,SAAS;EACT,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,0BAA0B;EAC1B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;EAC5B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,0BAA0B;EAC1B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".feature-card-single-item {\n  /* width: 298px; */\n  height: 303px;\n  border: 1px solid #e2e2e2;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n  gap: 20px;\n  padding-top: 40px;\n  padding-bottom: 40px;\n  padding-left: 20px;\n  padding-right: 20px;\n  margin-top: 20px;\n}\n\n.feature-card-icon-container {\n  min-height: 60px;\n  max-height: 60px;\n  min-width: 60px;\n  max-width: 60px;\n  height: 100%;\n  width: 100%;\n  border: 1px solid #0026f2;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.feature-card-icon {\n  height: 40px;\n  width: 40px;\n}\n\n.feature-card-title {\n  font-family: Space Grotesk;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 600;\n}\n\n.feature-card-detail {\n  font-family: Red Hat Display;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n}\n\n.feature-label {\n  color: #4c4356;\n  font-family: Space Grotesk;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 28px;\n}\n\n@media screen and (max-width: 1200px) {\n  .feature-card-title {\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
