import { Col, Image, Row, Typography } from "antd";
import React from "react";
import CustomButton from "../UI/CustomButton";
import { FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";

const FounderInfo = () => {
  return (
    <section className="relative founder-main-container">
      <section className="founder-container px-[100px] py-[80px] bg-[#E2E2E2]/10">
        <Row
          gutter={{
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
          }}
        >
          <Col lg={10} xl={10}>
            <Typography className="font-Space-Grotesk tex-mobile-view font-semibold text-[#272727] text-[38px] leading-[48.49px] capitalize">
              Meet Our Founder
            </Typography>
            <Typography className="mt-[16px] mr-[20px] font-Red-Hat-Display font-normal text-[#272727] text-[16px] leading-[25.06px]">
             <p>Nathaniel Hallford has been in the jobs industry for 14 years and he has built and used Headhuntable for himself the entire duration to close
              clients such as Meta, Pfizer, Cloud5, and many others.</p>

             <p>Headhuntable was initially built as a solution to provide job security to our founder and now many others are enjoying the unique benefits of our unique program.</p>
             
            </Typography>
            <div className="w-fit read-story-container mt-[32px] relative z-20">
            </div>
          </Col>

          {/* founder image */}
          <Col lg={7} xl={7} className="px-[29px]">
            <Image
              src="/images/landingPage/founderInfo/founder-image.jpeg"
              preview={false}
            />
          </Col>

          {/* founder info */}
          <Col lg={7} xl={7} className="">
            <section className="founder-img-container flex flex-col h-full ml-[25px] justify-end">

              <Typography className="mt-[32px] font-Space-Grotesk font-bold text-[32px] text-[#0026F2] leading-[41.6px] tracking-[1px]">
                Nathaniel Hallford
              </Typography>
              <Typography className="font-Red-Hat-Display font-normal text-[16px] text-[#272727] leading-[24px]">
                Founder of Headhuntable
              </Typography>
            </section>
          </Col>
        </Row>
      </section>
      <div className="absolute bottom-0 left-0 opacity-30">
        <Image
          src="/images/landingPage/founderInfo/Head H.png"
          preview={false}
        />
      </div>
      <div className="absolute bottom-0 left-0 opacity-30">
        <Image
          src="/images/landingPage/founderInfo/Head H-withbg.png"
          preview={false}
        />
      </div>
    </section>
  );
};

export default FounderInfo;
