import { Collapse, Typography } from "antd";
import React from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

const CollapseHeading = ({ text, heading }) => {
  const splitedString = heading.split(text);
  return (
    <Typography className="font-Space-Grotesk text-center font-bold text-[#272727] text-[38px] leading-[49.4px] capitalize">
      {splitedString[0]} <span className="text-[#0026F2]">{text}</span>{" "}
      {splitedString[1]}
    </Typography>
  );
};

const CustomCollapse = ({ activeKey, setActiveKey, items, heading, text }) => {
  return (
    <>
      <CollapseHeading heading={heading} text={text} />
      <Collapse
        ghost
        activeKey={activeKey}
        onChange={(value) => setActiveKey(value[0])}
        items={items}
        accordion
        expandIcon={({ isActive }) =>
          !isActive ? (
            <AiOutlinePlus
              size={20}
              color="#272727"
              className="hover:cursor-pointer"
            />
          ) : (
            <AiOutlineMinus
              size={20}
              color="#0026F2"
              className="hover:cursor-pointer"
            />
          )
        }
        expandIconPosition="right"
      />
    </>
  );
};

export default CustomCollapse;
