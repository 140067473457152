import { Col, Image, Row, Typography } from "antd";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";

const LinkItem = ({ title, links }) => {
  return (
    <>
      <Typography className="font-Space-Grotesk font-bold text-[18px] leading-[22.97px] text-white capitalize">
        {title}
      </Typography>
      <section className="mt-[33px] flex flex-col gap-[25px]">
        {links.map((link) => (
          <Typography className="capitalize font-Red-Hat-Display font-normal text-[16px] leading-[21.17px] text-white/80 hover:cursor-pointer">
            {link}
          </Typography>
        ))}
      </section>
    </>
  );
};

const Footer = () => {
  return (
    <section
      className="bg-[#272727] relative z-10"
      style={{ overflow: "hidden" }}
    >
      <Row
        className="grid-view-footer gap-3 px-[136px] pt-[147px] pb-[81px]"
        style={{
          color: "#FFF",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "9999",
          position: "relative",
        }}
      >
        <Col
          lg={6}
          xl={6}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image src="/images/logo-white.png" />
          <Typography className="mt-[10px] text-bg-white font-Red-Hat-Display font-medium text-[24.76px] leading-[24.31px] text-white">
            Head<span className="font-extrabold">Huntable</span>
          </Typography>
          <Typography
            className="mt-[10px] font-Red-Hat-Display font-normal text-[16px] leading-[28px] text-white"
            style={{ textAlign: "center", color: "#FFF" }}
          >
            Take control of your future and secure your dream job with us.
          </Typography>

          {/* social links */}
          <div className="share-icons flex gap-[23px] mt-[32px]">
            <FaFacebook
              size={20}
              color="#FFF"
              className="hover:cursor-pointer"
              onClick={() => {
                window.open(
                  "https://www.facebook.com/HeadHuntable.io",
                  "_blank"
                );
              }}
            />

            <FaTwitter
              size={20}
              color="#FFF"
              className="hover:cursor-pointer"
              onClick={() => {
                window.open("https://twitter.com/HeadHuntableio", "_blank");
              }}
            />
            <FaInstagram
              size={20}
              color="#FFF"
              className="hover:cursor-pointer"
              onClick={() => {
                window.open(
                  "https://www.instagram.com/headhuntable.io/",
                  "_blank"
                );
              }}
            />
            <FaLinkedin
              size={20}
              color="#FFF"
              className="hover:cursor-pointer"
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/headhuntable-io/",
                  "_blank"
                );
              }}
            />
          </div>
          <Typography
            className="mt-[10px] font-Red-Hat-Display font-normal text-[16px] leading-[28px] text-white"
            style={{ color: "#FFF" }}
          >
            admin@headhuntable.ai
          </Typography>
          <Typography
            className="text-center mt-[13px] font-Red-Hat-Display font-normal text-[12px] leading-[15.88px] text-white/80"
            style={{ color: "#FFF" }}
          >
            Createscape Coworking (Austin, TX)
          </Typography>
        </Col>

        {/* <Col lg={4} xl={4} sm={12} xs={12} className="info-text">
          <LinkItem
            title="Products"
            className="info-text"
            links={[
              "Hire Developers",
              "Hire Managers",
              "Hire Experts",
              "Referral Program",
              "Pricing",
            ]}
          />
        </Col>

        <Col lg={4} xl={4} sm={12} xs={12}>
          <LinkItem
            title="Quick Links"
            className="info-text"
            links={[
              "People",
              "About Us",
              "Privacy Policy",
              "Terms & Condition",
              "Ask Question",
            ]}
          />
        </Col>

        <Col lg={4} xl={4} sm={12} xs={12}>
          <LinkItem
            title="Company"
            className="info-text"
            links={[
              "About",
              "Contact Us",
              "Press Story",
              "Our Founders Story",
              "FAQ",
            ]}
          />
        </Col>

        <Col lg={4} xl={4} sm={12} xs={12}>
          <LinkItem
            title="More"
            className="info-text"
            links={["Ask A Question", "Get Started", "Login"]}
          />
        </Col> */}
      </Row>

      <div className="absolute top-0 ">
        <Image
          src="/images/footer-bg.png"
          preview={false}
          className="opacity-[.03]"
        />
      </div>

      <footer className=" border-t-[1px] border-white/10">
        <Typography
          className="text-center py-4 font-Red-Hat-Display font-normal text-[12px] leading-[15.88px] text-white/80"
          style={{ color: "#FFF" }}
        >
          Head Huntable. All Right Reserved. 2023
        </Typography>
      </footer>
    </section>
  );
};

export default Footer;
