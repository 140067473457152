import { Image, Typography } from "antd";
import AutoScroller from "../UI/auto-scroller/AutoScroller";

const CompanyWorkedAt = () => {
  const assetPath = "/images/landingPage/companyLogo";
  const logoData = [
    {
      path: `${assetPath}/viacom.png`,
    },
    {
      path: `${assetPath}/cbs.png`,
    },
    {
      path: `${assetPath}/metalogo.png`,
    },
    {
      path: `${assetPath}/z.png`,
    },
    {
      path: `${assetPath}/pfizer.png`,
    },
    {
      path: `${assetPath}/marriott.png`,
    },
    {
      path: `${assetPath}/ambig-health.png`,
    },
    {
      path: `${assetPath}/aeg.png`,
    },
    {
      label: "TMC",
      type: "text",
    },
    {
      path: `${assetPath}/baylor-scoot-white-logo.svg`,
    },
  ];
  return (
    <section className="company-worke-at-container-main bg-bg-white py-[80px] border-y-[1px] border-[#E2E2E2]/70">
      <section className="">
        <Typography className="text-center text-mobile-view font-Space-Grotesk font-semibold text-[#272727] text-[38px] leading-[48.49px] capitalize">
          Companies that opportunities have been secured at{" "}
          <span className="text-[#0026F2] text-mobile-view">
            through Headhuntable
          </span>
        </Typography>

        <div style={{ marginTop: 50 }}>
          <AutoScroller items={logoData} />
        </div>
      </section>
      <div className="absolute bottom-0 left-0 opacity-30">
        <Image
          src="/images/landingPage/founderInfo/Head H-withbg.png"
          preview={false}
        />
      </div>
    </section>
  );
};

export default CompanyWorkedAt;
