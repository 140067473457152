import { Image, Popover, Typography } from "antd";
import CustomButton from "../UI/CustomButton";
import { AiOutlineLike } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";
import { MenuOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";

const Navbar = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [menuItems, setMenuItems] = useState([
    { name: "Home", isActive: true, route: "/" },
    { name: "Pricing", isActive: false, route: "/packages" },
    { name: "The Process", isActive: false, route: "/how-does-it-work" },
    { name: "Our Features", isActive: false, route: "/features" },
    { name: "Testimonial", isActive: false, route: "/testimonial" },
  ]);

  const goToRoute = function (route) {
    // console.log("route is..", route);
    navigate(route);
  };

  return (
    <div className="wrapper nav header-padding flex justify-between items-center">
      <div
        className="flex gap-[13px] hover:cursor-pointer"
        onClick={() => navigate("/")}
      >
        <Image src="/images/logo.png" preview={false} />
        <div className="flex gap-0">
          <Typography className="font-Red-Hat-Display font-medium text-[#16F6CF] text-[26.06px] capitalize">
            Head
          </Typography>
          <Typography className="font-Red-Hat-Display font-extrabold text-[#0026F2] text-[26.06px] capitalize">
            Huntable
          </Typography>
        </div>
      </div>
      <div className="desktop-view">
        {menuItems.map((item, index) => {
          return (
            <button onClick={() => goToRoute(item.route)} key={index}>
              <span className="me-3">{item.name}</span>
            </button>
          );
        })}
      </div>
      <div className="desktop-view">
        <CustomButton
          textColor="text-[#FFF]"
          bgColor="bg-[#0026F2]"
          onClick={() => navigate("/get-started")}
        >
          Get Started <AiOutlineLike size={20} />
        </CustomButton>
      </div>
      <div className="moble-view">
        <Popover
          overlayStyle={{ width: "85%" }}
          content={
            <div className="">
              {menuItems.map((menuName, index) => {
                return (
                  <button onClick={() => goToRoute(menuName.route)} key={index}>
                    <p
                      className={`menu-item ${
                        menuName.isActive ? "menu-active" : ""
                      }`}
                      onClick={() => {
                        setMenuItems(
                          menuItems.map((el, i) => ({
                            ...el,
                            isActive: i == index,
                          }))
                        );
                      }}
                    >
                      {menuName.name}
                    </p>
                  </button>
                );
              })}
            </div>
          }
          trigger={"click"}
          placement="bottomRight"
          popupVisible={collapsed}
        >
          <Button className="flex justify-between items-center">
            <MenuOutlined />
          </Button>
        </Popover>
      </div>
      {/* login signup buttons */}
      {/* <section className="flex items-center gap-[24px]">
        <Typography className="hover:cursor-pointer font-Red-Hat-Display font-normal text-[#272727] text-[16px] leading-[21.17px]">
          <Link to="/login">Log In</Link>
        </Typography>
        <CustomButton textColor="text-[#FFF]" bgColor="bg-[#0026F2]">
          Sign up <AiFillGithub size={20} />
        </CustomButton>
      </section> */}
    </div>
  );
};

export default Navbar;
