import { Typography } from "antd";
import MainLayout from "../components/Layout/MainLayout";
import SliderAssistant from "../components/OurAssistants/SliderAssistant";

import Thumb1 from "../assets/ourassistants/thumb1.png";
import Thumb2 from "../assets/ourassistants/thumb2.png";
import Thumb3 from "../assets/ourassistants/thumb3.png";
import Thumb4 from "../assets/ourassistants/thumb4.png";

export default function AboutOurAssistantsPage() {
  const testimonials = [
    {
      youtubeVideoId: "O53dIDTIaAY",
      testimonial: "Headhuntable <br/>changed my life.",
      author: "Jane Smith",
      job: "Assistant",
      about:
        "A reliable digital ally committed to<br/>streamlining tasks and enhancing your productivity,<br/>Designed to seamlessly integrate into your daily life.",
      thumbnail: Thumb1,
    },
    {
      youtubeVideoId: "75yhYazRwRs",
      testimonial: "Headhuntable <br/>is very awesome.",
      author: "John Doe",
      job: "Assistant",
      about:
        "Passionate Frontend Developer skilled<br/>in creating intuitive user interfaces with</br> expertise in HTML, CSS, and</br/>JavaScript.",
      thumbnail: Thumb2,
    },
    {
      youtubeVideoId: "75yhYazRwRs",
      testimonial: "Headhuntable <br/>make's my life easier.",
      author: "Hattori Hanzo",
      job: "Assistant",
      about:
        "Passionate Frontend Developer skilled<br/>in creating intuitive user interfaces with</br> expertise in HTML, CSS, and</br/>JavaScript.",
      thumbnail: Thumb3,
    },

    {
      youtubeVideoId: "75yhYazRwRs",
      testimonial: "Headhuntable <br/>will rock you",
      author: "Bill Gates",
      job: "Assistant",
      about:
        "Passionate Frontend Developer skilled<br/>in creating intuitive user interfaces with</br> expertise in HTML, CSS, and</br/>JavaScript.",
      thumbnail: Thumb4,
    },
  ];

  return (
    <>
      <MainLayout isHomePage={false}>
        <div className="wrapper">
          <section className="next-container-spaceing relative z-0 py-[20px] mt-[-220px] text-center">
            <Typography className="font-Space-Grotesk font-semibold text-[#272727] tex-mobile-view text-[52px] leading-[72px] capitalize">
              <span className="text-[#0026F2] tex-mobile-view text-[36px] mb-4">
                Our Assistants
              </span>
              <br />
              Get to Know Our Assistants
              <br />
            </Typography>
            <span className="text-[#636766] tex-mobile-view text-[20px] mb-4">
              Discover our assistants' daily lives in our engaging vlog series,
              showcasing
              <br />
              the dynamic personalities and diverse talents powering
              HeadHuntable.
            </span>
          </section>
          <SliderAssistant testimonials={testimonials} />

          <section className="flex flex-col lg:flex-row items-center justify-center gap-8 py-5 mt-5 text-center relative overflow-hidden">
            {/* Right Container */}
            <div className="lg:w-1/2 ml-auto">
              {" "}
              {/* Adjusted to ml-auto to push it to the right */}
              <img
                src={require("../assets/ourassistants/assistantgirl.png")}
                alt="Your Image"
                className="w-[762px] h-[520px]"
              />
            </div>

            {/* Left Container */}
            <div className="absolute lg:w-[823px] lg:h-[401px] bg-bg-white rounded-lg overflow-hidden shadow-lg p-6 mr-[520px] text-left mt-10">
              <h2 className="font-Space-Grotesk text-3xl font-bold mb-4 z-10 relative inline-block mt-[90px] ml-[100px]">
                Your assistant is
                <br />
                always there for you!
              </h2>
              <p className="font-Red-Hat-Display text-gray-600 z-10 relative inline-block ml-[100px]">
                Throughout the entire process, your assistant will be helping
                you find
                <br />
                new opportunities, helping you schedule/reschedule your
                appointments
                <br />, and is a highly trained job seeker for you. Just sit
                back and relax and our
                <br />
                highly trained assistant will help you land your dream job.
              </p>
            </div>
          </section>

          <section className="next-container-spaceing bg-bg-default relative z-0 py-5 mt-5 text-center h-[500px]">
            <div className="flex justify-center items-center h-full">
              <div className="bg-testimonial-bg bg-cover bg-no-repeat w-full h-[485px] p-6 rounded-lg m-10 mx-auto flex flex-col items-center">
                <p className="text-p-text-white font-Space-Grotesk font-semibold text-center text-[32px] mt-[130px] py-10 p-20">
                  Take control of your future and secure
                  <br />
                  your dream job with Headhuntable.
                </p>
                <a className="bg-button-white text-[#0026F2] font-Red-Hat-Display text-[20px] px-4 py-2 rounded mt-4 inline-block cursor-pointer">
                  Get started on your journey
                </a>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
}
