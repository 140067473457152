import { Collapse, Typography } from "antd";
import React from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";



const CustomCollapseTestimonial = ({ activeKey, setActiveKey, items }) => {
  return (
    <>


      <Collapse
        ghost
        activeKey={activeKey}
        onChange={(value) => setActiveKey(value[0])}
        items={items}
        accordion
        expandIcon={({ isActive }) =>
          !isActive ? (
            <AiOutlinePlus
              size={20}
              color="#272727"
              className="hover:cursor-pointer"
            />
          ) : (
            <AiOutlineMinus
              size={20}
              color="#0026F2"
              className="hover:cursor-pointer"
            />
          )
        }
        expandIconPosition="right"
        style={{backgroundColor:'white'}}
      />

    </>
  );
};

export default CustomCollapseTestimonial;
