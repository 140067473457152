import { Image, Typography } from "antd";
import "./autoscroller.css";

const AutoScroller = ({ items }) => {
  console.log(items);
  if (!items && items.length === 0) return <>No data available</>;

  const scrollerSection = () =>
    items.map((obj, index) => {
      if (obj.type === "text")
        return (
          <Typography
            key={index}
            style={{ width: 175, fontFamily: `Gotham, sans-serif` }}
            color="#000"
            className="scroller__image align-center m-auto flex justify-center text-center text-mobile-view font-Space-Grotesk font-medium text-[#272727] text-[38px] leading-[48.49px] capitalize"
          >
            {obj.label}
          </Typography>
        );
      return (
        <Image
          key={index}
          preview={false}
          src={obj.path}
          wrapperClassName="scroller__image"
        />
      );
    });

  return (
    <div className="scroller">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "150px",
        }}
        className={`scroller-container ${
          items.length > 5 ? "scroller__wrapper" : ""
        }`}
      >
        {scrollerSection()}
        {/* duplicate the items array to avoid flickering in the slider */}
        {scrollerSection()}
      </div>
    </div>
  );
};

export default AutoScroller;
