// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 768px) {
  .as-seen-wrapper {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0 0 50px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/landingPage/as-seen.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;IACX,sBAAsB;IACtB,mBAAmB;IACnB,iBAAiB;EACnB;AACF","sourcesContent":["@media screen and (max-width: 768px) {\n  .as-seen-wrapper {\n    width: 100%;\n    flex-direction: column;\n    align-items: center;\n    padding: 0 0 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
