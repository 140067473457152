import { Col, Image, Row, Typography } from "antd";
import React from "react";
import CustomButton from "../UI/CustomButton";
import { AiOutlineLike } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Card = ({ order, image, stepCount, title, description }) => {
  return (
    <section className="mx-[205px]">
      <Row>
        <Col lg={12} xl={12} order={order === "rev" ? 1 : 0}>
          <div className="relative z-0">
            <Image
              src={`/images/howDoesItWork/${image}`}
              preview={false}
              className="z-10"
            />
            <div
              className={`w-full h-full absolute ${
                order === "rev"
                  ? "bg-[#16F6CF] -rotate-2"
                  : "bg-[#0026F2] rotate-2"
              } top-0 left-0 -z-10 `}
            ></div>
          </div>
        </Col>
        <Col
          lg={12}
          xl={12}
          className={`${order === "rev" ? "pr-[50px]" : "pl-[50px]"}`}
          order={order === "rev" ? 0 : 1}
        >
          <div className="flex flex-col justify-center h-full">
            <div className="flex gap-[20px]">
            {stepCount > 0 && <Typography className="px-[14px] py-[12px] bg-[#0026F2] uppercase font-Red-Hat-Display text-[12px] text-[#FFF] font-semibold">
                STEP {stepCount}
              </Typography>
              }
              <Typography className="font-Space-Grotesk text-[24px] text-[#272727] font-semibold leading-[160%] capitalize">
                {title}
              </Typography>
            </div>
            <Typography className="mt-[16px] font-Red-Hat-Display text-[16px] text-[#272727]/50 font-semibold">
              {description}
            </Typography>
          </div>
        </Col>
      </Row>
    </section>
  );
};

const GetStarted = () => {
  const navigate = useNavigate();
  return (
    <section className="relative px-[100px] py-[100px] bg-[#E2E2E2]/10">
      <section>
        <Typography className="font-Space-Grotesk text-center font-semibold text-[#272727] text-[38px] leading-[130%] capitalize -tracking-[1.14px]">
          <span className="text-[#0026F2] capitalize">how does it all work?</span>
        </Typography>
      </section>

      {/* cards section  */}
      <section className="mt-[50px] flex flex-col gap-[30px]">
        {/* card-1 */}
        <Card
          image="card-image-1.png"
          stepCount={1}
          title="Welcome Call"
          description="Join a welcome call with our team and learn how the process works."
        />

        {/* line */}
        <div className="flex justify-center">
          <Image src="/images/howDoesItWork/Line.png" preview={false} />
        </div>

        {/* card-2 */}
        <Card
          order="rev"
          image="card-image-2.png"
          stepCount={2}
          title="Onboarding call"
          description="In the onboarding call we will learn about you as a person, who you are, what you are good at, what you are not good at? We will find out also what does a dream job look like for you. What would make you the happiest?"
        />

        {/* line */}
        <div className="flex justify-center">
          <Image src="/images/howDoesItWork/Line-1.png" preview={false} />
        </div>

        {/* card-3 */}
        <Card
          image="card-image-3.png"
          stepCount={3}
          title="Attend your interviews"
          description="After your onboarding call we will get to work with finding you opportunities that align best to your skillsets and experiences."
        />

        {/* line */}
        <div className="flex justify-center">
          <Image src="/images/howDoesItWork/Line.png" preview={false} />
        </div>

        {/* card-4 */}
        <Card
          order="rev"
          image="mentoring.jpg"
          stepCount={4}
          title="Get trained/mentored"
          description="In todays competitive job market to get through each round of the interview process you must have training and mentoring which will set you up for success. Each week you will attend weekly coaching sessions where our trained life coaches and career counselors will help you improve on your interviewing skills."
        />

        {/* line */}
        <div className="flex justify-center">
          <Image src="/images/howDoesItWork/Line-2.png" preview={false} />
        </div>

        {/* signup with github button */}
        <div className="w-fit mx-auto">
          <CustomButton
            textColor="text-[#FFF]"
            bgColor="bg-[#0026F2]"
            onClick={() => navigate("/get-started")}
          >
            Get Started <AiOutlineLike size={20} />
          </CustomButton>
        </div>
      </section>

      {/* line */}
      <div className="flex justify-center">
        <Image src="/images/howDoesItWork/Line.png" preview={false} />
      </div>

      {/* card-2 */}
      <Card
        order="rev"
        image="card-image-2.png"
        stepCount={-1}
        title="Headhuntable is always there for you!"
        description="Throughout the entire process Headhuntable will be helping you find new opportunities, helping you schedule/reschedule your appointments and is a highly trained consultant for you. Just sit back and relax and we will handle everything on your behalf."
      />

      <div className="absolute top-0 right-0 opacity-20 -z-10">
        <Image
          src="/images/landingPage/nextRoleSection/Head H.png"
          preview={false}
        />
      </div>
    </section>
  );
};

export default GetStarted;
