// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricing {
  padding: 50px;
}

@media screen and (max-width: 1700px) {
  .pricing {
    padding: 50px 30px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/pricingPage/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE;IACE,6BAA6B;EAC/B;AACF","sourcesContent":[".pricing {\n  padding: 50px;\n}\n\n@media screen and (max-width: 1700px) {\n  .pricing {\n    padding: 50px 30px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
