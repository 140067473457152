import { Typography } from "antd";
import { useState } from "react";

import CustomCollapseTestimonial from "../UI/CustomCollapseTestimonial";
export const Question = ({ activeKey, children, itemKey }) => {
  return (
    <Typography
      className={`font-Space-Grotesk font-medium ${
        activeKey === itemKey ? "text-[#0026F2]" : "text-[#272727]"
      } text-[24px] leading-[42px] text-left`}
    >
      {children}
    </Typography>
  );
};

export const CollapseSection = ({ children }) => {
  return (
    <Typography className="font-Red-Hat-Display font-normal text-[16px] leading-[30px] text-[#272727]/70  text-left">
      {children}
    </Typography>
  );
};

const FAQTestimonial = () => {
  const [activeKey, setActiveKey] = useState("0");
  const items = [
    {
      key: "1",
      label: (
        <Question activeKey={activeKey} itemKey="1">
          What is the benefit of utilizing your service?
        </Question>
      ),
      children: (
        <CollapseSection>
          Our service provides autonomy. Whether you have work and just need
          more work. Whether you have an existing job and need a new job. On
          average it takes about two to three months to find a new role in
          todays job market and about twenty to thirty hours of time to find a
          new job. With using Headhuntable you will be able to cut that time
          down to around 3 to 5 hours per week.
        </CollapseSection>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <Question activeKey={activeKey} itemKey="2">
    //       What can my assistant help me do?
    //     </Question>
    //   ),
    //   children: <CollapseSection>lorem ipsum</CollapseSection>,
    // },
    // {
    //   key: "3",
    //   label: (
    //     <Question activeKey={activeKey} itemKey="3">
    //       Do you help me find jobs or also contracts?
    //     </Question>
    //   ),
    //   children: <CollapseSection>Bush hid the facts</CollapseSection>,
    // },
    // {
    //   key: "4",
    //   label: (
    //     <Question activeKey={activeKey} itemKey="4">
    //       How is this service fully autonomous?
    //     </Question>
    //   ),
    //   children: <CollapseSection>Lorem Ipsum</CollapseSection>,
    // },
  ];
  return (
    <section className=" py-[100px] border-1 border-black bg-[#f3f4f6]/50 relative z-10">
      <CustomCollapseTestimonial
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        items={items}
        heading=""
        text=""
      />
    </section>
  );
};

export default FAQTestimonial;
