// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 998px) {
  .desktop-view {
    display: none !important;
  }
}
@media (min-width: 998px) {
  .moble-view {
    display: none !important;
  }
}

/* @media (max-width: 767px) {
  .header-padding {
    padding: 20px 25px 20px 25px !important;
  }
}
@media (max-width: 1024px) {
  .header-padding {
    padding: 30px 50px 30px 50px !important;
  }
}

@media (max-width: 360px) {
  .header-padding {
    padding: 20px 25px 20px 25px !important;
  }
} */

.nav {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.menu-item {
  display: block;
  padding: 6px 4px 6px 4px;
  /* background: #eefffb; */
  border-radius: 6px;
  text-align: center;
  width: 150px;
  cursor: pointer;
}

.menu-active {
  background: #eefffb;
}
.me-3 {
  margin-left: 2vh;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/Navbar.css"],"names":[],"mappings":"AAAA;EACE;IACE,wBAAwB;EAC1B;AACF;AACA;EACE;IACE,wBAAwB;EAC1B;AACF;;AAEA;;;;;;;;;;;;;;;GAeG;;AAEH;EACE,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE,cAAc;EACd,wBAAwB;EACxB,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB","sourcesContent":["@media (max-width: 998px) {\n  .desktop-view {\n    display: none !important;\n  }\n}\n@media (min-width: 998px) {\n  .moble-view {\n    display: none !important;\n  }\n}\n\n/* @media (max-width: 767px) {\n  .header-padding {\n    padding: 20px 25px 20px 25px !important;\n  }\n}\n@media (max-width: 1024px) {\n  .header-padding {\n    padding: 30px 50px 30px 50px !important;\n  }\n}\n\n@media (max-width: 360px) {\n  .header-padding {\n    padding: 20px 25px 20px 25px !important;\n  }\n} */\n\n.nav {\n  padding-top: 20px !important;\n  padding-bottom: 20px !important;\n}\n\n.menu-item {\n  display: block;\n  padding: 6px 4px 6px 4px;\n  /* background: #eefffb; */\n  border-radius: 6px;\n  text-align: center;\n  width: 150px;\n  cursor: pointer;\n}\n\n.menu-active {\n  background: #eefffb;\n}\n.me-3 {\n  margin-left: 2vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
