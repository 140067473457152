import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <HubspotProvider>
      <ConfigProvider theme={{ hashed: false }}>
        <App />
      </ConfigProvider>
    </HubspotProvider>
  </StrictMode>
);
