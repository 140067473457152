import { Button, Typography } from "antd";
import React from "react";

const CustomButton = ({ children, bgColor, textColor, className, onClick }) => {
  const onHoverBg = `hover:${bgColor}/20`;
  const newClassName = `hover:cursor-pointer px-[44px] py-[14.5px] h-[50px] rounded-none ${bgColor} ${className} ${onHoverBg}`;
  console.log(onHoverBg);
  return (
    <Button className={newClassName} onClick={onClick}>
      <Typography
        className={`font-Red-Hat-Display font-bold ${textColor} flex items-center gap-2 text-[16px] leading-[21.17px]`}
      >
        {children}
      </Typography>
    </Button>
  );
};

export default CustomButton;
