import { Col, Image, Row, Typography } from "antd";
import React from "react";
import CustomButton from "../UI/CustomButton";
import { AiOutlineLike } from "react-icons/ai";
import "./HowItWorks.css";
import { useNavigate } from "react-router-dom";

const NextRoleSection = () => {
  const navigate = useNavigate();
  return (
    <section className=" next-container-spaceing relative z-0 bg-[#FFF] py-[80px]">
      <section className=" next-role-container">
        <Typography className="font-Space-Grotesk font-semibold text-[#272727] tex-mobile-view  text-[38px] leading-[48.49px] capitalize">
          <span className="text-[#0026F2] tex-mobile-view ">
            your career. automated.
          </span>
        </Typography>

        {/* free try row */}
        <Row
          gutter={{
            xs: 6,
            sm: 6,
            md: 6,
            lg: 12,
          }}
          className="mt-[40px]"
        >
          <Col lg={12} xl={12}>
            <Image
              src="/images/landingPage/nextRoleSection/next-role-section-hero.png"
              preview={false}
            />
          </Col>
          <Col
            lg={12}
            xl={12}
            className="px-[40px] flex flex-col justify-center mt-[2px] "
          >
            <Typography className="w-10/12 font-Red-Hat-Display font-normal font-width-mobile text-[#272727] text-[20px] leading-[25.06px]">
              Imagine how much more time you would have if you just focused on
              your work and making money?
              <br />
              <br />
              Imagine just giving your resume and coversheet to a company and
              having interviews lined up on your calendar.
              <br />
              <br />
              Imagine all the times you have been rejected or ghosted in the job
              search process and you didn't know why?
              <br />
              <br />
              Receive training from the best in the industry on how to speak and
              sell yourself. On average close deals 80% more.
            </Typography>

            <Typography className="mt-[32px] w-10/12 font-Red-Hat-Display font-price-mobile font-semibold text-[#272727] text-[22px] leading-[33px] italic">
              Starting at $200 per month for human automation with AI (we call
              it human-led AI).
            </Typography>

            <div className="w-fit mt-[32px]">
              <CustomButton
                textColor="text-[#FFF]"
                bgColor="bg-[#0026F2]"
                onClick={() => {
                  navigate("/get-started");
                }}
              >
                Get Started <AiOutlineLike size={20} />
              </CustomButton>
            </div>
          </Col>
        </Row>
      </section>

      <div className="absolute top-0 right-0 opacity-10">
        <Image
          src="/images/landingPage/nextRoleSection/Head H.png"
          preview={false}
        />
      </div>
    </section>
  );
};

export default NextRoleSection;
