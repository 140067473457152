import MainLayout from "../components/Layout/MainLayout";
import FaqQuestionFormContainer from "../components/pricingPage/FaqQuestionFormContainer";
import Plans from "../components/pricingPage/Plans";
import FAQ from "../components/pricingPage/FAQ";

const Pricing = () => {
  return (
    <MainLayout
      isHomePage={false}
      headerTitle="Complete automation of your career."
    >
      <div className="wrapper">
        <Plans />
        <FAQ />
        <FaqQuestionFormContainer />
      </div>
    </MainLayout>
  );
};

export default Pricing;
