import { Col, Row } from "antd";
import { useEffect } from "react";
import MainLayout from "../components/Layout/MainLayout";
import "./style.css";

const GetStartedPage = () => {
  const htmlContent =
    '<script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script><script>hbspt.forms.create({region:"na1",portalId:"44317800",formId:"e026874e-c372-4c87-ba51-c137d07b70ee"})</script><link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet"><script src="https://assets.calendly.com/assets/external/forms.js" type="text/javascript" async></script><script type="text/javascript">window.addEventListener("load",function(){Calendly.initHubspotForm({id:"e026874e-c372-4c87-ba51-c137d07b70ee",url:"https://calendly.com/api/form_builder/forms/4e27abca-326e-487d-95d3-dc4dd9818ef4/submissions"})})</script>';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainLayout
      className=" py-[100px] bg-[#f3f4f6]/50 relative z-10"
      headerTitle="Start your journey."
      subHeaderTitle="Your first step is a welcome call with our talented staff."
    >
      <div className="wrapper bg-[#f3f4f6]/50">
        <div className=" header-containar">
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col lg={12} xl={12}>
              <div
                style={{ marginTop: "30px" }}
                className="hubspot-form-iframe"
              >
                <iframe
                  src="https://headhuntable.ai/iframe.html"
                  style={{ width: "100%", height: "700px" }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </MainLayout>
  );
};

export default GetStartedPage;
