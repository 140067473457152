import MainLayout from "../components/Layout/MainLayout";
import FaqContainer from "../components/FAQPage/FaqContainer";
import NextRole from "../components/howDoestItWork/NextRole";
import GetStarted from "../components/howDoestItWork/GetStarted";

const HowDoesItWork = () => {
  return (
    <MainLayout isHomePage={false} headerTitle="How Does it Work">
      <div className="wrapper">
        <NextRole />
        <GetStarted />
        <FaqContainer />
      </div>
    </MainLayout>
  );
};

export default HowDoesItWork;
