import "./features.css";
const FeatureItem = (props) => {
  return (
    <>
      <section className="feature-card-single-item">
        <div className={"feature-card-icon-container"}>
          <img src={props.icon} className="feature-card-icon" alt="" />
        </div>
        <h1 className="feature-card-title">{props.title}</h1>
        <p className="feature-card-detail">{props.description}</p>
      </section>
    </>
  );
};

export default FeatureItem;
