import { Col, Image, Row, Typography } from "antd";
import CustomButton from "../UI/CustomButton";
import "./Header.css";
import { useNavigate } from "react-router-dom";

const Header = ({ isHomePage, headerTitle, subHeaderTitle }) => {
  const navigate = useNavigate();
  return (
    <div className="wrapper header-containar">
      {isHomePage ? (
        <Row
          gutter={{
            xs: 6,
            sm: 6,
            md: 6,
            lg: 12,
          }}
        >
          <Col lg={12} xl={12}>
            <div className="main-contailner">
              <Typography className="font-Space-Grotesk tex-mobile-view font-semibold text-[#272727] text-[48px] leading-[61.25px]">
                Automate your career.
              </Typography>
              <div style={{ display: "flex" }}>
                <Typography className="text-[#0026F2] mr-[10px] font-Space-Grotesk tex-mobile-view font-semibold text-[48px] tex-mobile-view leading-[61.25px]">
                  You focus on work and pleasure.
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography className="text-[#272727] font-Space-Grotesk tex-mobile-view font-semibold text-[22px] tex-mobile-view leading-[61.25px]">
                  <i>
                    We focus on finding you work and helping you close more
                    work.
                  </i>
                </Typography>
              </div>
              <Typography className="mt-[24px] font-Red-Hat-Display font-normal text-[#272727] text-[18px] leading-[25.06px]">
                Welcome to your future:
                <br />
                1 - Submit your resume to us.
                <br />
                2 - Attend interviews in a "speed dating" like approach.
                <br />3 - Talk to your mentor/life coach to help you close the
                deal and <strong>10x</strong> your income each week. <br />4 -
                Enjoy life.
              </Typography>
              <div className="mt-[32px] btn-lets-get-start w-fit relative z-10">
                <CustomButton
                  bgColor="bg-[#16F7CF]"
                  textColor="text-[#000]"
                  onClick={() => navigate("/get-started")}
                >
                  Lets Get Started
                </CustomButton>
              </div>
            </div>
          </Col>
          <Col lg={12} xl={12}>
            {isHomePage && (
              <Image src="/images/outlooktowaters.jpg" preview={false} />
            )}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col lg={16} xl={16} className="">
            <div className="flex flex-col justify-center h-full pr-[64px] w-10/12 relative">
              {/* spots */}
              <div className="w-[75px] h-[75px] bg-[#16F7CF] blur-[97px] absolute top-10 -left-12"></div>

              <Typography className="font-Space-Grotesk font-semibold text-[#272727] text-[48px] leading-[61.25px]">
                {headerTitle}
              </Typography>
            </div>
          </Col>
          <Col lg={16} xl={16} className="">
            <div className="flex flex-col justify-center h-full pr-[64px] w-10/12 relative">
              {/* spots */}
              <div className="w-[75px] h-[75px] bg-[#16F7CF] blur-[97px] absolute top-10 -left-12"></div>

              <Typography className="font-Space-Grotesk font-semibold text-[#272727] text-[20px] leading-[61.25px]">
                {subHeaderTitle}
              </Typography>
            </div>
          </Col>
          <Col lg={8} xl={8}>
            <Image
              src="/images/landingPage/header/Head-H-header.png"
              preview={false}
              // width="100%"
              // height="100%"
            />

            {/* spot */}
            <div className="w-[252px] h-[252px] bg-[#0026F2]/40 blur-[200px] absolute top-10 -left-12"></div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Header;
