import React from "react";
import QuestionForm from "./QuestionForm";

const FaqQuestionFormContainer = () => {
  return (
    <section className=" py-[100px] border-1 border-black bg-[#f3f4f6]/50 relative z-0">
      <QuestionForm />
    </section>
  );
};

export default FaqQuestionFormContainer;
