import MainLayout from "../components/Layout/MainLayout";
import FaqContainer from "../components/FAQPage/FaqContainer";
import FAQ1 from "../components/FAQPage/FAQ1";
import FAQ2 from "../components/FAQPage/FAQ2";

const FaqPage = () => {
  return (
    <MainLayout isHomePage={false} headerTitle="Frequently Asked Questions">
      <div className="wrapper">
        <FAQ1 />
        <FAQ2 />

        <FaqContainer />
      </div>
    </MainLayout>
  );
};

export default FaqPage;
