import { Col, Image, Row, Typography } from "antd";
import { GiCheckMark } from "react-icons/gi";

import "./style.css";

const PlanCard = ({ title, details, type }) => {
  return (
    <>
      <section
        className={`py-[28px] px-[34px] border-[1px] border-[#EDE6FA] ${
          type === "secondary" ? "bg-[#0026F2]" : "bg-[#FFF] relative"
        }`}
      >
        <Typography
          className={`font-Space-Grotesk font-semibold text-[38px] leading-[48.49px] ${
            type === "secondary" ? "text-bg-white" : "text-[#1C1F1C]"
          }`}
        >
          {title}
        </Typography>
        <Typography
          className={`font-Red-Hat-Display font-normal text-[16px] leading-[28px] ${
            type === "secondary" ? "text-bg-white" : "text-[#1C1F1C]"
          }`}
        >
          Access to your assistant on slack
        </Typography>

        {/* bg-image */}
        <div className="absolute top-0 right-0">
          <Image src="/images/packages/Head-H.png" preview={false} />
        </div>
      </section>

      <section className="mt-[40px]">
        <section className="flex flex-col gap-5 mt-[36px] w-10/12">
          {details.map((detail) => (
            <div className="flex items-start gap-2">
              <GiCheckMark color="#0026F2" size={12} className="mt-2" />
              <Typography className="flex-1 font-Red-Hat-Display font-normal text-[16px] leading-[28px]">
                {detail}
              </Typography>
            </div>
          ))}
        </section>
      </section>
    </>
  );
};

const Plans = () => {
  return (
    <section className="pricing py-[100px] bg-[#f3f4f6]/50 relative z-10">
      <Typography className="font-Space-Grotesk text-center font-bold text-[#272727] text-[38px] leading-[49.4px] capitalize">
        One week free trial. <br /> 15 minutes free coaching/mentoring session.
      </Typography>
      <Typography className="font-Space-Grotesk text-center font-bold text-[#272727] text-[38px] leading-[49.4px] capitalize">
        Pay per hour <span className="text-[#0026F2]">$20 </span>
        <br /> Paid either in blocks or "pay as you go".
      </Typography>
      <Row gutter={20} className=" justify-center">
        <Col lg={12} xl={12} md={24} xs={24} sm={24}>
          <PlanCard
            title="$200 per block (10 hours)"
            details={[
              "Receive high quality appointments on your calendar each week",
              "Access to your assistant on slack",
              "Your assistant is basically an executive assistant, they are responsible for helping you automate your job search process so that you can focus just on what you do best whether that be writing code, running teams as a scrummaster, or architecting a very complex AI application.",
              "Daily reporting of what jobs you were submitted to and daily reporting",
              "Access to a top expert in the jobs industry for one hour each week for coaching on how to close jobs and contracts.",
            ]}
            type="primary"
          />
        </Col>
        <Col lg={12} xl={12} md={24} xs={24} sm={24}>
          <PlanCard
            title="Pay as you go - $20 per hour"
            details={[
              "Receive high quality appointments on your calendar each week.",
              "Access to your assistant on slack",
              "Daily reporting of what jobs you were submitted to and daily reporting",
            ]}
            type="secondary"
          />
        </Col>
      </Row>
    </section>
  );
};

export default Plans;
