// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-containar {
  margin-top: 20px !important;
}

@media (max-width: 767px) {
  /* .header-containar {
    padding: 20px 30px 20px 35px !important;
  } */
  .main-contailner {
    margin-top: 1% !important;
  }
  .tex-mobile-view {
    font-size: 30px !important;
    margin-bottom: -12px !important;
  }
  .btn-lets-get-start {
    margin-bottom: 15px !important;
  }
  .grid-view-footer {
    padding-left: 50px !important;
    padding-right: 50px !important;
    gap: 0px !important;
  }
  .share-icons {
    margin-bottom: 50px !important;
  }
  .info-text {
    margin-bottom: 50px !important;
  }
}
.main-contailner {
  margin-top: 13%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/Header.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE;;KAEG;EACH;IACE,yBAAyB;EAC3B;EACA;IACE,0BAA0B;IAC1B,+BAA+B;EACjC;EACA;IACE,8BAA8B;EAChC;EACA;IACE,6BAA6B;IAC7B,8BAA8B;IAC9B,mBAAmB;EACrB;EACA;IACE,8BAA8B;EAChC;EACA;IACE,8BAA8B;EAChC;AACF;AACA;EACE,eAAe;AACjB","sourcesContent":[".header-containar {\n  margin-top: 20px !important;\n}\n\n@media (max-width: 767px) {\n  /* .header-containar {\n    padding: 20px 30px 20px 35px !important;\n  } */\n  .main-contailner {\n    margin-top: 1% !important;\n  }\n  .tex-mobile-view {\n    font-size: 30px !important;\n    margin-bottom: -12px !important;\n  }\n  .btn-lets-get-start {\n    margin-bottom: 15px !important;\n  }\n  .grid-view-footer {\n    padding-left: 50px !important;\n    padding-right: 50px !important;\n    gap: 0px !important;\n  }\n  .share-icons {\n    margin-bottom: 50px !important;\n  }\n  .info-text {\n    margin-bottom: 50px !important;\n  }\n}\n.main-contailner {\n  margin-top: 13%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
