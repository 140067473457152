import React from "react";
import QuestionForm from "../pricingPage/QuestionForm";
import { Image } from "antd";

const FaqContainer = () => {
  return (
    <section className=" py-[100px] border-1 border-black bg-[#f3f4f6]/50 relative z-10">
      <QuestionForm />
      <div className="absolute top-0 right-0 opacity-20 -z-10">
        <Image
          src="/images/landingPage/nextRoleSection/Head H.png"
          preview={false}
        />
      </div>
    </section>
  );
};

export default FaqContainer;
