import { Col, Row } from "antd";
import FaqContainer from "../components/FAQPage/FaqContainer";
import MainLayout from "../components/Layout/MainLayout";
import FeatureItem from "../components/featurePage/FeatureItem";

import "./style.css";

const Feature1 = require("../assets/features-1.png");
const Feature2 = require("../assets/features-2.png");
const Feature3 = require("../assets/features-3.png");
const Feature4 = require("../assets/features-4.png");
const Feature5 = require("../assets/features-5.png");
const Feature6 = require("../assets/features-6.png");
const Feature7 = require("../assets/features-7.png");
const Feature8 = require("../assets/features-8.png");
const Feature9 = require("../assets/features-9.png");
const Feature10 = require("../assets/features-10.png");
const Feature11 = require("../assets/features-11.png");

const FeaturePage = () => {
  const featuresList = [
    {
      icon: Feature1,
      title: "Google Calendar",
      description:
        "Integration with your google calendar so that appointments with the tech companies just appear on your calendar.",
    },
    {
      icon: Feature2,
      title: "Multi-Sources",
      description:
        "Integration with opportunities from many sources on a rolling basis.",
    },
    {
      icon: Feature3,
      title: "Hidden Opportunities",
      description: "We find opportunities that are posted and not posted.",
    },
    {
      icon: Feature4,
      title: "Calendly Integration",
      description:
        "Integration with calendly so that inside recruiters at tech companies can book with you directly.",
    },
    {
      icon: Feature5,
      title: "Appointment Alerts",
      description:
        "Text alerts when a new appointment was created and placed on your calendar.",
    },
    {
      icon: Feature6,
      title: "Direct Call Routing",
      description:
        "Call routing so that recruiters and interested companies call your phone directly with the opportunities.",
    },
    {
      icon: Feature9,
      title: "Opportunity Brain",
      description:
        'Our "opportunity brain" is constantly learning about you and learning which opportunities you will excel at so that we will avoid opportunities that are not of interest to you."',
    },
    {
      icon: Feature10,
      title: "Mock Interviews",
      description:
        'Talk with your own full time assistant whenever you need "the extra" touch and need to have a mock interview done to fine tune your approach.',
    },
    {
      icon: Feature11,
      title: "Personal Assistant",
      description:
        "Work directly with your full time assistant to manage your calendar, your appointments, and your life.",
    },
    {
      icon: Feature10,
      title: "Career Coaching",
      description:
        "Work each week with a career coach who will coach you on what to say during job interviews and who will be your advocate in your job search process.",
    },
  ];

  return (
    <MainLayout
      className=" py-[100px] bg-[#f3f4f6]/50 relative z-10"
      headerTitle="Our Features"
    >
      <div className="wrapper feature-container  bg-[#f3f4f6]/50">
        <p className="feature-label">
          Our innovative platform keeps finding work for you whether it be
          contracts, full time work, part time work, or freelance. We automate
          the entire process so that you can focus on your day job and your
          life.
        </p>
        <Row gutter={20}>
          {featuresList.map((feature, index) => (
            <Col key={index} span={6} xl={6} lg={8} md={8} sm={24} xs={24}>
              <FeatureItem
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
              />
            </Col>
          ))}
        </Row>
      </div>
      <div className="wrapper">
        <FaqContainer />
      </div>
    </MainLayout>
  );
};

export default FeaturePage;
