import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Col, Image, Row, Typography } from "antd";
import Divider from "../UI/Divider";



const SliderAssistant = ({ testimonials }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? testimonials.length - 1 : prevSlide - 1));
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === testimonials.length - 1 ? 0 : prevSlide + 1));
  };

  return (
    <div className="relative w-full max-w-screen-xl mx-auto">
      <div className="slider-container overflow-hidden">
        <div className="slider flex items-center justify-center transition-transform duration-500 ease-in-out transform">
          <div
            key={currentSlide}
            className={`slide w-full h-full md:w-auto md:flex-shrink-0`}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Left column (YouTube video) */}
              <div className="md:order-1">
                {/* Embed YouTube video */}
                <iframe
                style={{borderTopLeftRadius:20,borderBottomLeftRadius:20}}
                  title={`YouTube Video ${currentSlide + 1}`}
                  width="500px"
                  height="530"
                  src={`https://www.youtube.com/embed/${testimonials[currentSlide].youtubeVideoId}`}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>

              {/* Right column (Quote) */}
              <div className="md:order-2 text-left">
              {/* Quote */}
              <div className="bg-gray-300 p-4 text-left">
               <img
                  src={require('../../assets/ourassistants/avatar1.png')}
                  className="h-150 w-150 ml-[-50px] mt-[-40px]"
                  alt="Avatar"
                />
                  <footer className="mt-2 text-gray-600 text-left ml-[20px] mt-[-50px]">
                
                     <p className="font-Red-Hat-Display font-semibold  text-[36px]">{testimonials[currentSlide].author}</p>
                     <p className="font-Red-Hat-Display font-light  text-[20px] text-testimonial-p-1 mt-5 mb-5">{testimonials[currentSlide].job}</p>

                     <Typography className="font-Red-Hat-Display font-medium text-[#272727] tex-mobile-view text-[20px] mt-5 leading-[40px] capitalize">
                      <div dangerouslySetInnerHTML={{ __html: testimonials[currentSlide].about }} />
                    </Typography>
                    <Image src={require('../../assets/social/linkedin.png')} className="mt-5"/>
                  </footer>
           
              </div>
            </div>



            </div>
          </div>
        </div>
      </div>

      {/* Thumbnail */}
      <div className="flex justify-center items-center mt-4 mx-auto">
        {testimonials.map((testimonial, index) => (
            <img
            key={index}
            src={testimonial.thumbnail}
            alt={`Thumbnail ${index + 1}`}
            style={{
                width: index === currentSlide ? "240px" : "200px",
                height: index === currentSlide ? "140px" : "120px",
                objectFit: "cover",
                borderRadius: "5px",
                border: index === currentSlide ? "3px solid #16F7CF" : "1px solid #D1D5DB",
                margin: "0 8px",
                opacity: index === currentSlide  ? 1 : 0.5,
            }}
            />
        ))}
        </div>


      {/* Navigation buttons */}
      <button
        onClick={handlePrevSlide}
        className="absolute left-0 top-1/3 transform -translate-y-1/2 p-2 bg-button-blue text-button-text-white"
      >
        <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
      </button>
      <button
        onClick={handleNextSlide}
        className="absolute right-0 top-1/3 transform -translate-y-1/2 p-2 bg-button-blue text-button-text-white"
      >
        <FontAwesomeIcon icon={faChevronRight} className="text-lg" />
      </button>
    </div>
  );
};

export default SliderAssistant;
