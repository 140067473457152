import { Col, Image, Input, Row, Typography } from "antd";
import { useState } from "react";
import CustomButton from "../UI/CustomButton";
import axios from "axios";

const QuestionForm = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    if (!name || !email || !message) {
      return;
    }
    const hubspot_response = await submit_hubspot_form(email, name, message);
    setEmail("");
    setMessage("");
    setName("");
  };

  const submit_hubspot_form = async (email, name, message) => {
    const portalId = "44317800";
    const formGuid = "8bc1e4bc-63bb-4cd1-8487-78e1fb51a7dc";
    const config = {
      // important!
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields: [
          {
            name: "fullname",
            value: name,
          },

          {
            name: "email",
            value: email,
          },
          {
            name: "message",
            value: message,
          },
        ],
      },
      config
    );
    return response;
  };

  return (
    <section className="bg-[#fff]">
      <Row className=" justify-center">
        <Col
          lg={12}
          xl={12}
          md={24}
          sm={24}
          xs={24}
          className="pt-[60px] relative z-0"
        >
          <Typography className="font-Space-Grotesk font-bold text-[#272727] text-[38px] leading-[49.4px] capitalize">
            Have <span className="text-[#0026F2]">questions?</span>
          </Typography>
          <Typography className="mt-[16px] w-10/12 font-Red-Hat-Display font-normal text-[16px] leading-[25.6px] text-[#272727] ">
            Fill out the form and a Head Huntable representative will contact
            you as soon as possible. For immediate assistance, please call our
            sales line or email our customer support.
          </Typography>
          <div className="flex justify-center mt-[30px] relative z-0">
            <div className="relative w-full flex justify-center">
              <div className="relative">
                <Image
                  src="/images/packages/Man-Thinking-1.png"
                  preview={false}
                  width="100%"
                  height="100%"
                />
              </div>
              <div className="absolute bottom-0 -z-10">
                <Image
                  src="/images/packages/background-H.png"
                  preview={false}
                  width={400}
                  height={400}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col lg={12} xl={12} md={24} sm={24} xs={24} className="py-[60px]">
          <section className="border-[1px] border-[#272727]/10 px-[40px] py-[50px] shadow-lg">
            <section className="flex flex-col gap-[24px]">
              <InputBox
                label="Name"
                placeholder="Your name"
                type="text"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <InputBox
                label="Email"
                placeholder="example@gmail.com"
                type="text"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <InputBox
                label="Message"
                placeholder="I Would like to know about..."
                type="textarea"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </section>

            <div className="flex justify-end mt-[32px]">
              <CustomButton
                bgColor="bg-[#0026F2]"
                textColor="text-[#FFF]"
                onClick={handleSubmit}
              >
                Submit
              </CustomButton>
            </div>
          </section>
        </Col>
      </Row>
    </section>
  );
};

export default QuestionForm;

const InputBox = ({ label, placeholder, type, onChange }) => {
  return (
    <div>
      <Typography className="uppercase font-Red-Hat-Display font-medium text-[14px] leading-[18.52px] tracking-[1px]">
        {label} <span className="text-[#0026F2]">*</span>
      </Typography>
      {type === "text" && (
        <Input
          placeholder={placeholder}
          type={type}
          className="rounded-none p-[16px] mt-[10px]"
          onChange={onChange}
        />
      )}
      {type === "textarea" && (
        <Input.TextArea
          style={{
            resize: "none",
          }}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          rows={3}
          className="rounded-none p-[16px] mt-[10px]"
        />
      )}
    </div>
  );
};
