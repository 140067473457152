import { Col, Image, Row, Typography } from "antd";

const BrainAlwaysLearning = () => {
  return (
    <>
      <Row>
        <Col lg={12} xl={12}>
          <Typography className="font-Space-Grotesk font-bold capitalize">
            The "Opportunity Brain" Keeps Learning Always
          </Typography>
          <p><strong>headhuntable.io</strong> is not just a service that is here for you when you have a job it's a service that is here for you during your life.
            Whether you are working and just want more work our opportunity brain is constantly searching for work and using helpful humans to guide it along.

            It's available on demand, you can turn it on or off in seconds.
          </p>
        </Col>
        <Col lg={12} xl={12}>
          
        </Col>
      </Row>
    </>
  );
};

export default BrainAlwaysLearning;