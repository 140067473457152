import { Image, Typography } from "antd";

import "./as-seen.css";

const AsSeen = () => {
  return (
    <section className="company-worke-at-container-main py-[100px] border-y-[1px] border-[#E2E2E2]/70">
      <section className="">
        <Typography className="text-center text-mobile-view font-Space-Grotesk font-semibold text-[#272727] text-[38px] leading-[48.49px] capitalize mb-1 pb-20">
          As Seen In
        </Typography>

        <div className="flex gap-20 justify-center align-middle  as-seen-wrapper">
          <Image
            width={200}
            preview={false}
            src="/images/homepage/holywood-life.svg"
          />
          <Image
            width={200}
            preview={false}
            wrapperStyle={{
              backgroundColor: "black",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              borderRadius: 10,
            }}
            src="/images/homepage/rectech-logo-white-horiz.png"
          />
          <Image
            width={200}
            preview={false}
            src="/images/homepage/rolling-stones.svg"
          />
        </div>
      </section>
    </section>
  );
};

export default AsSeen;
