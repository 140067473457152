import JobMatching from "../components/opportunityBrain/JobMatching";
import DescriptionOpportunityBrain from "../components/opportunityBrain/DescriptionOpportunityBrain";
import BrainAlwaysLearning from "../components/opportunityBrain/BrainAlwaysLearning";
import QuestionForm from "../components/opportunityBrain/QuestionForm";
import MainLayout from "../components/Layout/MainLayout";

const OpportunityBrain = () => {
  return (
    <MainLayout
      className=" py-[100px] bg-[#f3f4f6]/50 relative z-10"
      headerTitle="Opportunity Brain"
    >
      <div className="wrapper">
        <JobMatching />
        <DescriptionOpportunityBrain />
        <BrainAlwaysLearning />
        <QuestionForm />
      </div>
    </MainLayout>
  );
};

export default OpportunityBrain;
