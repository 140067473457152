import { Image, Typography } from "antd";
import MainLayout from "../components/Layout/MainLayout";
import SliderTestimonial from "../components/TestimonialPage/SliderTestimonial";

import Thumb1 from "../assets/testimonial/3.png";
import Thumb2 from "../assets/testimonial/1.png";
import Thumb3 from "../assets/testimonial/4.png";
import Thumb4 from "../assets/testimonial/2.png";
import FAQTestimonial from "../components/TestimonialPage/FAQTestimonial";

export default function TestimonialPage() {
  const testimonials = [
    {
      youtubeVideoId: "nkQDq_MgQPo",
      testimonial: "Headhuntable <br/>changed my life.",
      author: "Ariel Manigsaca",
      job: "Client",
      about:
        "Passionate Frontend Developer skilled<br/>in creating intuitive user interfaces with</br> expertise in HTML, CSS, and</br/>JavaScript.",
      thumbnail: Thumb1,
    },
    {
      youtubeVideoId: "ACJSOzAqbdc",
      testimonial: "Headhuntable <br/>is very awesome.",
      author: "Mia Lagunju",
      job: "Client",
      about:
        "Passionate Frontend Developer skilled<br/>in creating intuitive user interfaces with</br> expertise in HTML, CSS, and</br/>JavaScript.",
      thumbnail: Thumb2,
    },
    {
      youtubeVideoId: "jd6XPhYZwTY",
      testimonial: "Headhuntable <br/>make's my life easier.",
      author: "Jon Albrecht",
      job: "Client",
      about:
        "Passionate Frontend Developer skilled<br/>in creating intuitive user interfaces with</br> expertise in HTML, CSS, and</br/>JavaScript.",
      thumbnail: Thumb3,
    },

    {
      youtubeVideoId: "0GnkDBWU4RA",
      testimonial: "Headhuntable <br/>will rock you",
      author: "Henry Eze",
      job: "Client",
      about:
        "Passionate Frontend Developer skilled<br/>in creating intuitive user interfaces with</br> expertise in HTML, CSS, and</br/>JavaScript.",
      thumbnail: Thumb4,
    },
  ];

  return (
    <>
      <MainLayout isHomePage={false}>
        <div className="wrapper">
          <section className="next-container-spaceing relative z-0 py-[20px] mt-[-220px] text-center">
            <Typography className="font-Space-Grotesk font-semibold text-[#272727] tex-mobile-view text-[52px] leading-[72px] capitalize">
              <span className="text-[#0026F2] tex-mobile-view text-[36px] mb-4">
                Testimonials
              </span>
              <br />
              How did Headhuntable <br /> Change My Life?
            </Typography>
          </section>

          <section className="next-container-spaceing relative z-0 py-[20px] mt-[5px] text-center">
            <SliderTestimonial testimonials={testimonials} />
          </section>

          <section className="next-container-spaceing bg-bg-default relative z-0 py-5 mt-5 text-center h-[500px]">
            <div className="flex justify-center items-center h-full">
              <div className="bg-testimonial-bg bg-cover bg-no-repeat w-full h-[485px] p-6 rounded-lg m-10 mx-auto flex flex-col items-center">
                <p className="text-p-text-white font-Space-Grotesk font-semibold text-center text-[32px] mt-[130px] py-10 p-20">
                  Take control of your future and secure
                  <br />
                  your dream job with Headhuntable.
                </p>
                <a className="bg-button-white text-[#0026F2] font-Red-Hat-Display text-[20px] px-4 py-2 rounded mt-4 inline-block cursor-pointer">
                  Get started on your journey
                </a>
              </div>
            </div>
          </section>

          <section className="bg-bg-default relative z-0 py-[20px] text-center mt-10">
            <p className="font-Space-Grotesk bg-bg-default font-semibold text-left text-[48px] ml-10">
              Frequently Asked Questions
            </p>
            <FAQTestimonial />
          </section>

          <section className="relative text-center h-[650px]">
            <div className="flex justify-center bg-testimonial-bg2 bg-cover bg-no-repeat w-full h-full mt-[-22px]">
              {/* First Div */}
              <div className="w-full sm:w-1/3 bg-gray-300 p-4 mt-[50px]">
                <Image src={require("../assets/testimonial/person.png")} />
              </div>

              {/* Second Div */}
              <div className="w-full sm:w-1/3 bg-gray-400 p-4 mr-10 mt-[130px]">
                <p className="font-Space-Grotesk text-[36px] text-p-text-blue text-left leading-[30px] m-5">
                  Contact Us
                </p>
                <p className="font-Space-Grotesk text-[46px]  text-left leading-[30px] m-5">
                  Have Questions?
                </p>
                <p className="font-Red-Hat-Display text-[20px]  text-left leading-[30px] m-5 text-testimonial-p-1">
                  Fill out the form, and a Head Huntable representative will
                  contact you as soon as possible. For immediate assistance,
                  please call our sales line or email our customer support.
                </p>

                <div className="flex items-center ml-4 mt-2">
                  <Image
                    src={require("../assets/testimonial/mail.png")}
                    alt="Phone"
                  />
                  <a
                    href="mailto:admin@headhuntable.io"
                    className="font-Red-Hat-Display text-[20px] text-testimonial-p-1 ml-1"
                  >
                    <span className="ml-5  text-[20px] text-testimonial-p-1">
                      &nbsp;admin@headhuntable.ai
                    </span>
                  </a>
                </div>
              </div>

              {/* Third Div */}
              <div className="w-full sm:w-1/3 bg-gray-500 p-4 mt-[130px] mr-10">
                <form className="overflow-hidden bg-white opacity-[0.9]">
                  <div className="mb-2">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 text-left"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="mt-1 p-2 w-full border rounded-md border-[#DADEF2] "
                      placeholder="Your Name"
                      // Add any necessary onChange or value props for handling input
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 text-left"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="mt-1 p-2 w-full border rounded-md border-[#DADEF2] "
                      placeholder="Your Email"
                      // Add any necessary onChange or value props for handling input
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="message"
                      className="block text-sm font-medium text-gray-700 text-left"
                    >
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      className="mt-1 p-2 w-full border rounded-md border-[#DADEF2] "
                      placeholder="Your Message"
                      // Add any necessary onChange or value props for handling input
                    ></textarea>
                  </div>
                  <div className="mb-4 text-right">
                    <button
                      type="submit"
                      className="px-4 py-2 bg-button-blue text-button-text-white rounded-md hover:bg-blue-600"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
}
