// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="email"] {
  height: 50px !important;
}

.webinar-form {
  height: 250px;
}

.feature-container {
  padding: 50px;
}

.pricing {
  padding: 50px;
}

.pricing .ant-row .ant-col {
  margin-top: 40px;
}

@media screen and (max-width: 1700px) {
  .pricing {
    padding: 50px 30px !important;
  }
  .feature-container {
    padding: 30px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,6BAA6B;EAC/B;EACA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":["input[type=\"email\"] {\n  height: 50px !important;\n}\n\n.webinar-form {\n  height: 250px;\n}\n\n.feature-container {\n  padding: 50px;\n}\n\n.pricing {\n  padding: 50px;\n}\n\n.pricing .ant-row .ant-col {\n  margin-top: 40px;\n}\n\n@media screen and (max-width: 1700px) {\n  .pricing {\n    padding: 50px 30px !important;\n  }\n  .feature-container {\n    padding: 30px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
