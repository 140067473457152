import { Typography } from "antd";
import React, { useState } from "react";
import CustomCollapse from "../UI/CustomCollapse";

export const Question = ({ activeKey, children, itemKey }) => {
  return (
    <Typography
      className={`font-Space-Grotesk font-medium ${
        activeKey === itemKey ? "text-[#0026F2]" : "text-[#272727]"
      } text-[24px] leading-[42px]`}
    >
      {children}
    </Typography>
  );
};

export const CollapseSection = ({ children }) => {
  return (
    <Typography className="font-Red-Hat-Display font-normal text-[16px] leading-[30px] text-[#272727]/70 ">
      {children}
    </Typography>
  );
};

const FAQ1 = () => {
  const [activeKey, setActiveKey] = useState("0");
  const items = [
    {
      key: "1",
      label: (
        <Question activeKey={activeKey} itemKey="1">
          Step 1: Submit your CV and coversheet.
        </Question>
      ),
      children: (
        <CollapseSection>
          Our team will work with the best in the industry to format and update
          your resume to improve your conversions. We will then pass your resume
          through focus groups to make sure that you are setup for success
        </CollapseSection>
      ),
    },
    {
      key: "2",
      label: (
        <Question activeKey={activeKey} itemKey="2">
          Step 2: Onboarding with your assistant
        </Question>
      ),
      children: (
        <CollapseSection>
          In this call you will be onboarded with your assistant and she/he will
          learn more about you and what you love to do versus what you don't
          love to do. Their job is to provide you the job that you LOVE doing so
          that you can continue to focus on your busy life.
        </CollapseSection>
      ),
    },
    {
      key: "3",
      label: (
        <Question activeKey={activeKey} itemKey="3">
          How is this autonomous?
        </Question>
      ),
      children: (
        <CollapseSection>
          We are a people centric company and we utilize people and technology
          to bring you a great experience in your job searching process. You
          will have your own assigned assistant who has the sole purpose of
          finding you a job that you absolutely love and that pays you great
          money.
        </CollapseSection>
      ),
    },
    {
      key: "4",
      label: (
        <Question activeKey={activeKey} itemKey="4">
          What else can my assistant do?
        </Question>
      ),
      children: (
        <CollapseSection>
          She/he can help with scheduling and rescheduling appointments,
          negotiate job offers on your behalf, talk to recruiters on your
          behalf, and really completely automate the job searching process so
          that you just show up for zoom calls.
        </CollapseSection>
      ),
    },
    {
      key: "5",
      label: (
        <Question activeKey={activeKey} itemKey="5">
          How do I know this is a good investment on my time?
        </Question>
      ),
      children: (
        <CollapseSection>
          We provide the training thats needed for our assistants to excel at
          what they do and they receive much better training than inside
          recruiters do at tech companies.
        </CollapseSection>
      ),
    },
  ];
  return (
    <section className=" py-[100px] border-1 border-black bg-[#f3f4f6]/50 relative z-10">
      <CustomCollapse
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        items={items}
        heading="How does the process work?"
        text="process"
      />
    </section>
  );
};

export default FAQ1;
