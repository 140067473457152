import { Image, Typography } from "antd";
import CustomButton from "../UI/CustomButton";
import { useNavigate } from "react-router-dom";

const GetStartedSection = () => {
  const navigate = useNavigate();
  return (
    <section
      className="get-started-container "
      style={{ zIndex: "9999999999", position: "relative" }}
    >
      <section className="w-full py-[57px] bg-[#2B59FF] relative top-[100px]">
        <Typography className="text-center text-bg-white footer-mobile-view font-Space-Grotesk font-bold text-white text-[38px] leading-[48.49px] capitalize">
          Lets Get Started
        </Typography>
        <Typography className="mt-4 mx-auto w-7/12 text-center text-bg-white  font-Red-Hat-Display font-normal text-white text-[18px] leading-[32px]">
          Click on the Get Started button and schedule your welcome call.
        </Typography>

        <section className="flex justify-center gap-[12px] mt-[32px]">
          <div className="w-fit ">
            <CustomButton
              className="get-start-btn"
              textColor="text-[#2B59FF]"
              bgColor="bg-[#FFF]"
              onClick={() => {
                navigate("/get-started");
              }}
            >
              Get Started
            </CustomButton>
          </div>

          <div className="w-fit get-start-btn">
            <CustomButton
              textColor="text-[#FFF]"
              bgColor="bg-[#2B59FF]"
              className="border-[1px] border-white  get-start-btn"
              onClick={() => {
                navigate("/faq");
              }}
            >
              Ask A Question
            </CustomButton>
          </div>
        </section>

        <div className="absolute top-0 left-0">
          <Image
            src="/images/landingPage/getStarted/Head-H-right.png"
            preview={false}
          />
        </div>

        <div className="absolute top-0 right-0">
          <Image
            src="/images/landingPage/getStarted/Head-H-left.png"
            preview={false}
          />
        </div>
      </section>
    </section>
  );
};

export default GetStartedSection;
