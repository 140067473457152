import React from "react";

import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutOurAssistantsPage from "./pages/AboutOurAssistantsPage";
import FaqPage from "./pages/FaqPage";
import FeaturePage from "./pages/FeaturePage";
import GetStartedPage from "./pages/GetStartedPage";
import HomePage from "./pages/HomePage";
import HowDoesItWork from "./pages/HowDoesItWork";
import OpportunityBrainPage from "./pages/OpportunityBrainPage";
import Pricing from "./pages/Pricing";
import TourPage from "./pages/TourPage";
import Testimonial from './pages/TestimonialPage'
const App = () => {
  return (
    <>
      <BrowserRouter basename="/">
        <HubspotProvider>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/get-started" element={<GetStartedPage />} />
            <Route path="/packages" element={<Pricing />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/how-does-it-work" element={<HowDoesItWork />} />
            <Route path="/tour" element={<TourPage />} />
            <Route path="/features" element={<FeaturePage />} />
            <Route path="/testimonial" element={<Testimonial />} />
            <Route
              path="/opportunity-brain"
              element={<OpportunityBrainPage />}
            />
            <Route
              path="/about-our-assistants"
              element={<AboutOurAssistantsPage />}
            />
          </Routes>
        </HubspotProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
