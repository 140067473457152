import { useState } from "react";
import CustomCollapse from "../UI/CustomCollapse";
import { CollapseSection, Question } from "./FAQ1";

const FAQ2 = () => {
  const [activeKey, setActiveKey] = useState("0");
  const items = [
    {
      key: "1",
      label: (
        <Question activeKey={activeKey} itemKey="1">
          Passive Job Searching
        </Question>
      ),
      children: (
        <CollapseSection>
          Since job security does not really exist we have implemented a
          solution to get you to as close to job security as possible. We will
          work in the background while you are doing your day to day job (if you
          already have a job) or if you don't have a job we'll be helping you
          find a job in a very expedited manner.
        </CollapseSection>
      ),
      className: "ant-collapse-item",
    },
    {
      key: "2",
      label: (
        <Question activeKey={activeKey} itemKey="2">
          Is this working already for others?
        </Question>
      ),
      children: (
        <CollapseSection>
          Yes we are constantly adding beta testers and customers on a weekly
          basis to ensure a high level of quality in the job searching process
          for the technology market.
        </CollapseSection>
      ),
    },
  ];
  return (
    <section className=" py-[100px] border-1 border-black bg-[#FFFFFF] relative z-10">
      <CustomCollapse
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        items={items}
        heading="What are the benefits?"
        text="benefits?"
      />
    </section>
  );
};

export default FAQ2;
