import MainLayout from "../components/Layout/MainLayout";
import NextRoleSection from "../components/landingPage/NextRoleSection";
import FounderInfo from "../components/landingPage/FounderInfo";
import CompanyWorkedAt from "../components/landingPage/CompanyWorkedAt";
import GetStartedSection from "../components/landingPage/GetStartedSection";
import AsSeen from "../components/landingPage/AsSeen";

const HomePage = () => {
  return (
    <MainLayout isHomePage={true}>
      {/* <HowItWorks /> */}
      <div className="wrapper">
        <NextRoleSection />
        <FounderInfo />
      </div>
      <CompanyWorkedAt />
      <div className="wrapper">
        <AsSeen />
        <GetStartedSection />
      </div>
    </MainLayout>
  );
};

export default HomePage;
