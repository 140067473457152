import React from 'react';

const Divider = ({ width, height, marginTop, marginBottom, color }) => {
  const dividerStyle = {
    width: width || '100%',
    height: height || '1px',
    marginTop: marginTop || '0',
    marginBottom: marginBottom || '0',
    backgroundColor: color || '#d1d5db!important', 
  };

  return <hr style={dividerStyle} />;
};

export default Divider;
