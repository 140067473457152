import React, { useState } from "react";
import CustomCollapse from "../UI/CustomCollapse";
import { CollapseSection, Question } from "../FAQPage/FAQ1";

const FAQ = () => {
  const [activeKey, setActiveKey] = useState("0");
  const items = [
    {
      key: "1",
      label: (
        <Question activeKey={activeKey} itemKey="1">
          What is the benefit of utilizing your service?
        </Question>
      ),
      children: (
        <CollapseSection>
          Our service provides autonomy. Whether you have work and just need
          more work. Whether you have an existing job and need a new job. On
          average it takes about two to three months to find a new role in
          todays job market and about twenty to thirty hours of time to find a
          new job. With using Headhuntable you will be able to cut that time
          down to around 3 to 5 hours per week.
        </CollapseSection>
      ),
    },
    {
      key: "2",
      label: (
        <Question activeKey={activeKey} itemKey="2">
          What can my assistant help me do?
        </Question>
      ),
      children: (
        <CollapseSection>
          Your assistant can assist with setting up job interviews, can
          reschedule/schedule appointments, can negotiate job offers on your
          behalf. When an assistant is hired through Headhuntable they go
          through a thorough vetting process that lasts usually around two to
          three months. Our founder and also other experts in the industry train
          the assistants to be <strong>VERY</strong> good at one thing which is
          finding a new job or opportunity for you.
        </CollapseSection>
      ),
    },
    {
      key: "3",
      label: (
        <Question activeKey={activeKey} itemKey="3">
          Do you help me find jobs or also contracts?
        </Question>
      ),
      children: (
        <CollapseSection>
          We find both jobs and contracts. You can utilize Headhuntable to help
          you find jobs (whether it be a part time job or a full time job) or
          even contracts. Our assistants come highly trained in helping you find
          the best opportunities which are available on the market.
        </CollapseSection>
      ),
    },
    {
      key: "4",
      label: (
        <Question activeKey={activeKey} itemKey="4">
          How is this service fully autonomous?
        </Question>
      ),
      children: (
        <CollapseSection>
          Well, in short it takes a long time to find work because the job
          market is so competitive. This translates to a lot of lost time that
          you could utilize doing something else, whether its spending time with
          your children, enjoying your lifestyle.
        </CollapseSection>
      ),
    },
    {
      key: "5",
      label: (
        <Question activeKey={activeKey} itemKey="5">
          What if I am not happy with the service?
        </Question>
      ),
      children: (
        <CollapseSection>
          Our goal is to make this service improve your life and change your
          life for the better. If you are not happy with the service, please
          reach out a highly trained specialist will be more than happy to
          assist you.
        </CollapseSection>
      ),
    },
  ];
  return (
    <section className=" py-[100px] border-1 border-black bg-[#FFF] relative z-10">
      <CustomCollapse
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        items={items}
        heading="Frequently Asked Questions"
        text="Questions"
      />
    </section>
  );
};

export default FAQ;
